.main-menu-connected__label-completed {
    padding: 0 7px;
    border-radius: 8px;
    text-wrap: nowrap;
    font-size: 11px;
    min-width: 50px;
    color: white;
}

.main-menu-connected__label-completed.green {
    background-color: #c8ece7;
}

.main-menu-connected__label-completed.red {
    background-color: #e19652;
}

.main-menu-connected__label-completed img {
    width: 16px;
    height: 16px;
}

.loader-container {
    margin-right: 0 !important;
} 