.organisation-consultation-prices__table-header {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.organisation-consultation-prices__table-header__col {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    width: 20%;
}

.organisation-consultation-prices__table-header__col img {
    width: 24px;
    height: 24px;
}

.organisation-consultation-prices__table-body__row{
    display: flex;
    align-items: center;
    justify-content: center;
}

.organisation-consultation-prices__table-body__col {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.organisation-consultation-prices__table-body__col-container {
    position: relative;
}

.organisation-consultation-prices__table-body__col span {
    position: absolute;
    top: 10px;
    right: 20px;
}

.organisation-consultation-prices__warning {
    right: -30px !important;
}

.organisation-consultation-prices__warning:hover .organisation-consultation-prices__tooltip-warning {
    display: block;
}

.organisation-consultation-prices__table__input-field {
    max-width: 100px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #C3CFD9;
    padding-left: 10px;
}

.organisation-consultation-prices__table-body__col.type-col {
    justify-content: flex-start;
    font-weight: 600;
}

.organisation-consultation-prices__table-body__apply-all-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    max-width: 100px;
    height: 50px;
    background-color: #50AEA0;
    color: white;
    font-weight: 700;
    box-shadow: 0 1px 3px 0 #cfd7df;
    border-radius: 4px;
    font-size: 12px;
}

.organisation-consultation-prices__table-body__row {
    margin-top: 24px;
}

.organisation-consultation-prices__table-body__row.apply-all {
    margin-top: 8px;
}

.organisation-consultation-prices__table-container {
    padding: 24px 0;
}

.organisation-consultation-prices__table__actions-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin: 50px 0;
}

.organisation-consultation-prices__table__actions-btns__cancel {
    background-color: white;
    border: 1px solid #C3CFD9;
    border-radius: 4px;
    padding: 12px 48px;
}

.organisation-consultation-prices__table__actions-btns__save {
    background-color: #3488D9;
    border-radius: 4px;
    border: none;
    padding: 12px 48px;
    color: white;
}

.organisation-consultation-prices__specific-hours .no-gutters .col-sm-4:first-of-type {
  display: none;
} 

.organisation-consultation-prices__specific-hours .col-sm-8 {
    flex: 0 0 100%;
    max-width: 100%;
}

.organisation-consultation-prices__table-header__category {
    font-weight: 600;
}

.organisation-consultation-prices__specific-hours h6,
.organisation-consultation-prices__table-body h6 {
    font-weight: 700;
}

.organisation-consultation-prices__icon-warning {
    width: 2rem;
}

.organisation-consultation-prices__tooltip-warning {
    padding: 4px;
    border: 1px solid black;
    background-color: #D8D8D8;
    position: absolute;
    width: 100px;
    top: -20px;
    left: 20px;
    z-index: 999;
    display: none;
    text-align: center;
}
