.clients__filter-all,
.clients__filter-pending {

    border-radius: 24px;
  background-color: #2C88D9;
  padding: 12px 32px;
  color: white;
  font-weight: 900;
  
}

.clients__filter {
    cursor: pointer;
}
.pagination {
    justify-content: flex-end;
}