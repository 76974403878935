body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #404040; /* TODO bind with props theme */

  line-height: normal;
}

html,
body,
#root {
  min-height: 100%;
  /* max-width: 100vw !important;
  overflow-x: hidden !important; */
}

.white_icon path {
  fill: white;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

input {
  font-family: 'Montserrat', sans-serif;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 30%;
  right: 30%;
  top: 40%;
  bottom: 40%;
  display: flex;
  flex-direction: column;
  /* padding: 10px 10px 10px 10px; */
  background: white;
  border-radius: 5px;
}

.navbar.navbar-default {
  border-bottom: 3px so;
}
.navbar-light .navbar-nav .nav-link {
  color: #151515 !important;
  margin: '15px';
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #1ba3c1 !important;
  font-weight: bold !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #1ba3c1 !important;
  font-weight: bold !important;
}

.StripeElement {
  box-sizing: border-box;
  flex: 1;
  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #fafafa;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* Modal Side Menu */

.modal {
  z-index: 1000003; /* Because navbar has a z-index of 1000001 and subnavbar 1000002 */
  padding: 0 !important;
}

/* .modal-dialog {
  width: 100% !important;
  max-width: none !important;
  margin: 0;
} */

.modal-body {
  padding: 0;
}
